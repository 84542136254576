<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.tenantId"
                  filterable
                  size="15"
                  @change="tenantChange"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.tenantName"
                    :value="value.tenantId"
                    :key="value.tenantName"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.date')">
                <el-date-picker
                  unlink-panels
                  v-show="formInline.dateType == 'day'"
                  value-format="yyyy-MM-dd"
                  v-model="dateRange"
                  :picker-options="pickerOptions"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Reconciliation_status')">
                <el-select v-model.trim="formInline.dealStatus" filterable size="15">
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.label"
                    :value="value.value"
                    :key="value.label"
                    v-for="value in reconciliation"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              ><el-button type="" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button type="" icon="el-icon-upload2" @click="exportData">导出 </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <el-tabs
        type="card"
        v-model.trim="formInline.channelType"
        @tab-click="handleTab"
        class="bgFFF"
      >
        <el-tab-pane v-for="tab in tabList" :key="tab.name" :label="tab.label" :name="tab.value">
          <!--列表区域-->
          <div class="tableWrapper">
            <el-table
              v-loading="loading"
              :data="tableData"
              style="width: 100%"
              ref="singleTable"
              highlight-current-row
            >
              <el-table-column
                align="center"
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                align="center"
                :prop="item.prop"
                :label="item.label"
                :min-width="item.width"
                v-for="item in tableCols"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
              <el-table-column prop="dealStatus" :label="$t('searchModule.Reconciliation_status')" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" style="padding: 0" @click="gobill(scope)">{{
                    scope.row.dealStatus == 0
                      ? "对账一致"
                      : scope.row.dealStatus == 1
                      ? "对账不一致"
                      : "已平账"
                  }}</el-button>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="130">
                <template slot-scope="scope">
                  <!--<el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>-->
                  <el-dropdown
                    style="color: #20a0ff; cursor: pointer"
                    @command="ctrlData($event, scope.$index, scope.row)"
                  >
                    <el-button type="text" size="small" style="padding: 0">
                      下载账单明细
                      <i class="el-icon-arrow-down" />
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <el-dropdown-item command="differ">下载差异文件</el-dropdown-item> -->
                      <el-dropdown-item command="wf">下载我方对账文件</el-dropdown-item>
                      <el-dropdown-item command="df">下载对方对账文件</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
export default {
  name: "generalBusiness",
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setTime(date1.getTime() - 1000 * 60 * 60 * 24);
    let startTime = date1;
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setTime(date.getTime() - 1000 * 60 * 60 * 24);
    let endTime = date;
    return {
      reconciliation: [
        { label: "对账一致", value: "0" },
        { label: "对账不一致", value: "1" },
        { label: "已平账", value: "2" },
      ],
      dealStatus: "",
      authority: this.$route.meta.authority,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > endTime;
        },
      },
      tabName: "4",
      index: 0,
      total: 0,
      date1: "",
      date2: "",
      pageSize: 15,
      pageNum: 1,
      startTime: startTime,
      endTime: endTime,
      loading: false,
      detailFlag: false,
      isDisabled: true,
      areaList: [],
      parkList: [],
      berthList: [],
      tableCols: [
        {
          prop: "billDate",
          label: "对账日期",
          width: "",
          formatter: (row, column, value) => {
            return dateFormat(new Date(value), "yyyy-MM-dd");
          },
        },
        {
          prop: "tenantName",
          label: this.$t("list.Payment_merchant"),
          width: "",
        },
        {
          prop: "sysMoney",
          label: "平台方收款金额",
          width: "100",
          formatter: (row, column) => {
            return row.sysMoney == 0 ? "0.00元" : row.sysMoney / 100 + "元";
          },
        },
        {
          prop: "sysRefundMoney",
          label: "平台方退款金额",
          width: "100",
          formatter: (row, column) => {
            return row.sysRefundMoney == 0 ? "0.00元" : row.sysRefundMoney / 100 + "元";
          },
        },
        {
          prop: "billMoney",
          label: "渠道方收款金额",
          width: "100",
          formatter: (row, column) => {
            return row.billMoney == 0 ? "0.00元" : row.billMoney / 100 + "元";
          },
        },
        {
          prop: "billRefundMoney",
          label: "渠道方退款金额",
          width: "100",
          formatter: (row, column) => {
            return row.billRefundMoney == 0 ? "0.00元" : row.billRefundMoney / 100 + "元";
          },
        },
        {
          prop: "difMoney",
          label: "收款对账差额",
          width: "",
          formatter: (row, column) => {
            return row.difMoney == 0 ? "0.00元" : row.difMoney / 100 + "元";
          },
        },
        {
          prop: "difRefundMoney",
          label: "退款对账差额",
          width: "",
          formatter: (row, column) => {
            return row.difRefundMoney == 0 ? "0.00元" : row.difRefundMoney / 100 + "元";
          },
        },
        // {
        //   prop: "dealStatus",
        //   label: "对账状态",
        //   width: "",
        //   formatter: (row, column) => {
        //     if (row.dealStatus == 0) {
        //       return "对账一致";
        //     } else if (row.dealStatus == 1) {
        //       return "对账不一致";
        //     } else if (row.dealStatus == 2) {
        //       return "已平账";
        //     }
        //   }
        // }
        // {
        //   prop: "hasDif",
        //   label: "是否存在差异",
        //   width: "",
        //   formatter: row => {
        //     if (row.hasDif) {
        //       return "是";
        //     } else {
        //       return "否";
        //     }
        //   }
        // },
        // {
        //   prop: "dealStatus",
        //   label: "是否平账",
        //   width: "",
        //   formatter: row => {
        //     if (row.dealStatus) {
        //       return "已平账";
        //     } else {
        //       return "未平账";
        //     }
        //   }
        // }
      ],
      tableData: [],
      dateRange: [],
      roleList: [],
      formInline: {
        startTime: dateFormat(startTime, "yyyy-MM-dd"),
        endTime: dateFormat(endTime, "yyyy-MM-dd"),
        dateType: "day",
        channelType: "4",
        hasDif: "-1",
        tenantId: "",
        dealStatus: "",
      },
      tabList: [
        { label: "微信", value: "4" },
        { label: "支付宝", value: "5" },
        { label: "招商银行", value: "16" },
        { label: "数字人民币", value: "17" },
        { label: "嘉联支付", value: "21" },
        { label: "农业银行", value: "32" },
      ],
    };
  },
  methods: {
    tenantChange(val) {
      this.formInline.tenantId = this.roleList.find((item) => item.tenantId == val).tenantId;

      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    exportData() {
      exportExcelNew("/acb/2.0/bill/export", this.formInline);
    },
    gobill(data) {
      console.log(this.tabName);
      if (data.row.dealStatus == 0) {
        return 1;
      } else if (data.row.dealStatus == 1) {
        this.$router.push({
          name: "billDiff",
          params: {
            startDate: dateFormat(new Date(data.row.billDate), "yyyy-MM-dd"),
            endDate: dateFormat(new Date(data.row.billDate), "yyyy-MM-dd"),
            tenantId: data.row.tenantId,
            type: 1,
            selectData: this.tabName,
          },
        });
      } else if (data.row.dealStatus == 2) {
        this.$router.push({
          name: "billDiff",
          params: {
            startDate: dateFormat(new Date(data.row.billDate), "yyyy-MM-dd"),
            endDate: dateFormat(new Date(data.row.billDate), "yyyy-MM-dd"),
            tenantId: data.row.tenantId,
            type: 2,
            selectData: this.tabName,
          },
        });
      }
      // this.$router.push({
      //   name: 'billDiff',
      //   params: {
      //       startDate: data.row.billDate,
      //       endDate: data.row.billDate,
      //       tenantId: data.row.tenantId
      //   }
      // })
    },
    /* 商户名称的下拉框 */
    // getRoleList () {
    //   this.$axios.get('/acb/2.0/operation/nameList').then(res => {
    //     this.loading = false;
    //     if (res.state == 0) {
    //       this.roleList = res.value;
    //       this.searchData();
    //     } else {
    //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //         confirmButtonText: this.$t('pop_up.Determine')
    //       });
    //     }
    //   })
    // },
    initDate() {
      let startDate = this.$moment().subtract(90, "days").format("YYYY-MM-DD");
      let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      this.dateRange = [startDate, endDate];
    },
    getRoleList() {
      this.$axios.get("/acb/2.0/bill/tenant/tenantList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.roleList = res.value;
          this.searchData();
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    resetForm() {
      this.formInline.tenantId = "";
      let startDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      this.dateRange = [startDate, endDate];
    },
    // 处理操作相关动作
    ctrlData(command, index, obj) {
      if (command == "wf") {
        this.downloadSys(obj);
      } else if (command == "df") {
        this.downloadChannel(obj);
      } else if (command == "differ") {
        this.downloadDif(obj);
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog() {
      if (this.startTime && this.endTime) {
        if (this.formInline.dateType == "day") {
          let startTime = dateFormat(new Date(this.startTime), "yyyy-MM-dd");
          let endTime = dateFormat(new Date(this.endTime), "yyyy-MM-dd");
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          let data1 = Date.parse(startTime.replace(/-/g, "/"));
          let data2 = Date.parse(endTime.replace(/-/g, "/"));
          let datadiff = data2 - data1;
          let time = 31 * 24 * 60 * 60 * 1000;
          if (datadiff >= time) {
            this.$alert("搜索时间不能超过31天", "提示");
            return false;
          }
        } else if (this.formInline.dateType == "month") {
          let startTime = dateFormat(new Date(this.startTime), "yyyy-MM");
          let endTime = dateFormat(new Date(this.endTime), "yyyy-MM");
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          let data1 = Date.parse(startTime.replace(/-/g, "/"));
          let data2 = Date.parse(endTime.replace(/-/g, "/"));
          let datadiff = data2 - data1;
          let time = 348 * 24 * 60 * 60 * 1000;
          if (datadiff >= time) {
            this.$alert("搜索时间不能超过12个月", "提示");
            return false;
          }
        } else if (this.formInline.dateType == "year") {
          let startTime = dateFormat(new Date(this.startTime), "yyyy");
          let endTime = dateFormat(new Date(this.endTime), "yyyy");
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          let data1 = Date.parse(startTime.replace(/-/g, "/"));
          let data2 = Date.parse(endTime.replace(/-/g, "/"));
          let datadiff = data2 - data1;
          let time = 348 * 2 * 24 * 60 * 60 * 1000;
          if (datadiff >= time) {
            this.$alert("搜索时间不能超过2年", "提示");
            return false;
          }
        }
        if (this.formInline.startTime > this.formInline.endTime) {
          // eslint-disable-next-line no-alert
          this.$alert("结束时间不能小于开始时间");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
        //		          this.$alert('日期不能为空', this.$t('pop_up.Tips'), {
        //		            confirmButtonText: this.$t('pop_up.Determine')
        //		          });
        //		          return false
      }
    },
    // 下载差异文件
    downloadDif(data) {
      const channelType = this.formInline.channelType;
      exportExcelNew("/acb/2.0/bill/diffDownload", {
        tenantId: data.tenantId,
        channelType: channelType,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    // 下载我方对账文件
    downloadSys(data) {
      const channelType = this.formInline.channelType;
      const params = {
        tenantId: data.tenantId,
        channelType: channelType,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      };
      // console.log(params)
      exportExcelNew("/acb/2.0/bill/download", {
        tenantId: data.tenantId,
        channelType: channelType,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    // 下载对方对账文件
    downloadChannel(data) {
      const channelType = this.formInline.channelType;
      exportExcelNew("/acb/2.0/bill/otherBilldownload", {
        tenantId: data.tenantId,
        channelType: channelType,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    handleTab(tab) {
      console.log(tab);
      this.tabName = tab.name;
      this.formInline.channelType = tab.name;
      this.searchData();
    },
    // 搜索
    searchData() {
      // if ((this.date1 && !this.date2) || (!this.date1 && this.date2)) {
      //   this.$alert("必须选择两个日期", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   });
      //   return;
      // }
      //
      let startTime = "";
      let endTime = "";
      if (!this.dateRange || this.dateRange == "") {
        this.$alert("日期不能为空！");
        return;
      } else {
        startTime = this.dateRange[0];
        endTime = this.dateRange[1];
      }
      this.formInline.startTime = startTime;
      this.formInline.endTime = endTime;
      this.tableData = [];
      this.formInline.page = this.pageNum;
      this.formInline.pageSize = this.pageSize;
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bill/list", {
          data: this.formInline,
        })
        .then((res) => {
          if (res.state == 0) {
            this.loading = false;
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.loading = false;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {},
  activated() {
    this.searchData();
  },
  created() {},
  mounted() {
    this.initDate();
    // this.searchData();
    this.getRoleList();
    // console.log(this.authority);
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;
  /deep/ .el-tabs--card>.el-tabs__header {
    margin-left: 20px;
  }
  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
    float: right;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  .btnWrapper {
    text-align: left;
    margin-top: 28px;
    float: left;
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
